import { default as test78OweUc7SZMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/pages/test.vue?macro=true";
import { default as indexogsm8WTYxiMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/example/pages/index.vue?macro=true";
import { default as indexGnQ7CCp6QCMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/account/pages/index.vue?macro=true";
import { default as detailscCkqwIsyzEMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/account/pages/personal/details.vue?macro=true";
import { default as passwordtfSHybt9hOMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/account/pages/personal/password.vue?macro=true";
import { default as detailsQMzuErYChYMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/account/pages/company/details.vue?macro=true";
import { default as enquirieslf3nzV4OYbMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/account/pages/company/enquiries.vue?macro=true";
import { default as utilitiesLs11oqXCKaMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/account/pages/company/utilities.vue?macro=true";
import { default as tenanciesDaj3Gd4M86Meta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/account/pages/company/tenancies.vue?macro=true";
import { default as indextXQoEW8T9FMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/analytics/pages/index.vue?macro=true";
import { default as indexPSIzK5v0wdMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/auth/pages/login/index.vue?macro=true";
import { default as indexfXSArdQOYCMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/auth/pages/forgot-password/index.vue?macro=true";
import { default as sentiYLgBfZ1lEMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/auth/pages/forgot-password/sent.vue?macro=true";
import { default as _91token_93qNWOcZb6tIMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/auth/pages/reset-password/[token].vue?macro=true";
import { default as featurestr0Nva5wpaMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/checkout/pages/checkout/features.vue?macro=true";
import { default as paymentfAgajHhJ9dMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/checkout/pages/checkout/payment.vue?macro=true";
import { default as indexzfaqOSrzW3Meta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/checkout/pages/index.vue?macro=true";
import { default as successunYW7Dj9r2Meta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/checkout/pages/success.vue?macro=true";
import { default as indexIYNAS5OcJnMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/dashboard/pages/index.vue?macro=true";
import { default as indexrx4pzCN2myMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/enquiries/pages/index.vue?macro=true";
import { default as indexumTofCgpkXMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/properties/pages/index.vue?macro=true";
import { default as indexswPFSljH3JMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/properties/pages/import/index.vue?macro=true";
import { default as addresseb5oMlsn9pMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/properties/pages/property/address.vue?macro=true";
import { default as aboutxr9lJP0mJ2Meta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/properties/pages/property/about.vue?macro=true";
import { default as listinglIgQp6jEKbMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/properties/pages/property/listing.vue?macro=true";
import { default as mediaV0POlaEfHcMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/properties/pages/property/media.vue?macro=true";
import { default as propertyNzo81BDKpgMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/properties/pages/property.vue?macro=true";
import { default as editEueO4uE4TbMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/properties/pages/property/[uuid]/edit.vue?macro=true";
import { default as partnership5TIyQmOn2JMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/resources/pages/partnership.vue?macro=true";
import { default as marketing_45materialZRG9LB8x8RMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/resources/pages/marketing-material.vue?macro=true";
import { default as indexgzFWJVbQD4Meta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/tenancies/pages/index.vue?macro=true";
import { default as indexRfAs06cMh8Meta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/utilities/pages/index.vue?macro=true";
import { default as detailsD0cwLLlkTaMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/utilities/pages/order/details.vue?macro=true";
import { default as tenantsVQ5YXxmIbWMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/utilities/pages/order/tenants.vue?macro=true";
import { default as summaryfDENC23bmVMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/utilities/pages/order/summary.vue?macro=true";
import { default as previewfTR7CudahaMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/utilities/pages/order/preview.vue?macro=true";
import { default as orderbIXSqmodEcMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/utilities/pages/order.vue?macro=true";
import { default as confirmationfMW8RsMlMHMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/utilities/pages/order/confirmation.vue?macro=true";
import { default as indexK6schfY8rIMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/utilities/pages/order/[uuid]/index.vue?macro=true";
export default [
  {
    name: test78OweUc7SZMeta?.name ?? "test",
    path: test78OweUc7SZMeta?.path ?? "/test",
    meta: test78OweUc7SZMeta || {},
    alias: test78OweUc7SZMeta?.alias || [],
    redirect: test78OweUc7SZMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/pages/test.vue").then(m => m.default || m)
  },
  {
    name: indexogsm8WTYxiMeta?.name ?? "example",
    path: indexogsm8WTYxiMeta?.path ?? "/example",
    meta: indexogsm8WTYxiMeta || {},
    alias: indexogsm8WTYxiMeta?.alias || [],
    redirect: indexogsm8WTYxiMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/example/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexGnQ7CCp6QCMeta?.name ?? "account-index",
    path: indexGnQ7CCp6QCMeta?.path ?? "/account",
    meta: indexGnQ7CCp6QCMeta || {},
    alias: indexGnQ7CCp6QCMeta?.alias || [],
    redirect: indexGnQ7CCp6QCMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/account/pages/index.vue").then(m => m.default || m)
  },
  {
    name: detailscCkqwIsyzEMeta?.name ?? "personal-details-settings",
    path: detailscCkqwIsyzEMeta?.path ?? "/account/personal/details",
    meta: detailscCkqwIsyzEMeta || {},
    alias: detailscCkqwIsyzEMeta?.alias || [],
    redirect: detailscCkqwIsyzEMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/account/pages/personal/details.vue").then(m => m.default || m)
  },
  {
    name: passwordtfSHybt9hOMeta?.name ?? "personal-password-settings",
    path: passwordtfSHybt9hOMeta?.path ?? "/account/personal/password",
    meta: passwordtfSHybt9hOMeta || {},
    alias: passwordtfSHybt9hOMeta?.alias || [],
    redirect: passwordtfSHybt9hOMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/account/pages/personal/password.vue").then(m => m.default || m)
  },
  {
    name: detailsQMzuErYChYMeta?.name ?? "company-details-settings",
    path: detailsQMzuErYChYMeta?.path ?? "/account/company/details",
    meta: detailsQMzuErYChYMeta || {},
    alias: detailsQMzuErYChYMeta?.alias || [],
    redirect: detailsQMzuErYChYMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/account/pages/company/details.vue").then(m => m.default || m)
  },
  {
    name: enquirieslf3nzV4OYbMeta?.name ?? "company-enquiries-settings",
    path: enquirieslf3nzV4OYbMeta?.path ?? "/account/company/enquiries",
    meta: enquirieslf3nzV4OYbMeta || {},
    alias: enquirieslf3nzV4OYbMeta?.alias || [],
    redirect: enquirieslf3nzV4OYbMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/account/pages/company/enquiries.vue").then(m => m.default || m)
  },
  {
    name: utilitiesLs11oqXCKaMeta?.name ?? "company-utilities-settings",
    path: utilitiesLs11oqXCKaMeta?.path ?? "/account/company/utilities",
    meta: utilitiesLs11oqXCKaMeta || {},
    alias: utilitiesLs11oqXCKaMeta?.alias || [],
    redirect: utilitiesLs11oqXCKaMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/account/pages/company/utilities.vue").then(m => m.default || m)
  },
  {
    name: tenanciesDaj3Gd4M86Meta?.name ?? "company-tenancies-settings",
    path: tenanciesDaj3Gd4M86Meta?.path ?? "/account/company/tenancies",
    meta: tenanciesDaj3Gd4M86Meta || {},
    alias: tenanciesDaj3Gd4M86Meta?.alias || [],
    redirect: tenanciesDaj3Gd4M86Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/account/pages/company/tenancies.vue").then(m => m.default || m)
  },
  {
    name: indextXQoEW8T9FMeta?.name ?? "analytics",
    path: indextXQoEW8T9FMeta?.path ?? "/analytics",
    meta: indextXQoEW8T9FMeta || {},
    alias: indextXQoEW8T9FMeta?.alias || [],
    redirect: indextXQoEW8T9FMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/analytics/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexPSIzK5v0wdMeta?.name ?? "login",
    path: indexPSIzK5v0wdMeta?.path ?? "/login",
    meta: indexPSIzK5v0wdMeta || {},
    alias: indexPSIzK5v0wdMeta?.alias || [],
    redirect: indexPSIzK5v0wdMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/auth/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexfXSArdQOYCMeta?.name ?? "forgot-password",
    path: indexfXSArdQOYCMeta?.path ?? "/forgot-password",
    meta: indexfXSArdQOYCMeta || {},
    alias: indexfXSArdQOYCMeta?.alias || [],
    redirect: indexfXSArdQOYCMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/auth/pages/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: sentiYLgBfZ1lEMeta?.name ?? "forgot-password-sent",
    path: sentiYLgBfZ1lEMeta?.path ?? "/forgot-password/sent",
    meta: sentiYLgBfZ1lEMeta || {},
    alias: sentiYLgBfZ1lEMeta?.alias || [],
    redirect: sentiYLgBfZ1lEMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/auth/pages/forgot-password/sent.vue").then(m => m.default || m)
  },
  {
    name: _91token_93qNWOcZb6tIMeta?.name ?? "reset-password",
    path: _91token_93qNWOcZb6tIMeta?.path ?? "/reset-password/:token",
    meta: _91token_93qNWOcZb6tIMeta || {},
    alias: _91token_93qNWOcZb6tIMeta?.alias || [],
    redirect: _91token_93qNWOcZb6tIMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/auth/pages/reset-password/[token].vue").then(m => m.default || m)
  },
  {
    name: indexzfaqOSrzW3Meta?.name ?? "checkout",
    path: indexzfaqOSrzW3Meta?.path ?? "/checkout",
    children: [
  {
    name: featurestr0Nva5wpaMeta?.name ?? "checkout-features",
    path: featurestr0Nva5wpaMeta?.path ?? "features",
    meta: featurestr0Nva5wpaMeta || {},
    alias: featurestr0Nva5wpaMeta?.alias || [],
    redirect: featurestr0Nva5wpaMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/checkout/pages/checkout/features.vue").then(m => m.default || m)
  },
  {
    name: paymentfAgajHhJ9dMeta?.name ?? "checkout-payment",
    path: paymentfAgajHhJ9dMeta?.path ?? "payment",
    meta: paymentfAgajHhJ9dMeta || {},
    alias: paymentfAgajHhJ9dMeta?.alias || [],
    redirect: paymentfAgajHhJ9dMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/checkout/pages/checkout/payment.vue").then(m => m.default || m)
  }
],
    meta: indexzfaqOSrzW3Meta || {},
    alias: indexzfaqOSrzW3Meta?.alias || [],
    redirect: indexzfaqOSrzW3Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/checkout/pages/index.vue").then(m => m.default || m)
  },
  {
    name: successunYW7Dj9r2Meta?.name ?? "checkout-success",
    path: successunYW7Dj9r2Meta?.path ?? "/checkout/success",
    meta: successunYW7Dj9r2Meta || {},
    alias: successunYW7Dj9r2Meta?.alias || [],
    redirect: successunYW7Dj9r2Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/checkout/pages/success.vue").then(m => m.default || m)
  },
  {
    name: indexIYNAS5OcJnMeta?.name ?? "dashboard",
    path: indexIYNAS5OcJnMeta?.path ?? "/",
    meta: indexIYNAS5OcJnMeta || {},
    alias: indexIYNAS5OcJnMeta?.alias || [],
    redirect: indexIYNAS5OcJnMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/dashboard/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexrx4pzCN2myMeta?.name ?? "enquiries",
    path: indexrx4pzCN2myMeta?.path ?? "/enquiries",
    meta: indexrx4pzCN2myMeta || {},
    alias: indexrx4pzCN2myMeta?.alias || [],
    redirect: indexrx4pzCN2myMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/enquiries/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexumTofCgpkXMeta?.name ?? "properties-index",
    path: indexumTofCgpkXMeta?.path ?? "/properties",
    meta: indexumTofCgpkXMeta || {},
    alias: indexumTofCgpkXMeta?.alias || [],
    redirect: indexumTofCgpkXMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/properties/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexswPFSljH3JMeta?.name ?? "properties-import",
    path: indexswPFSljH3JMeta?.path ?? "/properties/import",
    meta: indexswPFSljH3JMeta || {},
    alias: indexswPFSljH3JMeta?.alias || [],
    redirect: indexswPFSljH3JMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/properties/pages/import/index.vue").then(m => m.default || m)
  },
  {
    name: propertyNzo81BDKpgMeta?.name ?? "properties-property-create",
    path: propertyNzo81BDKpgMeta?.path ?? "/property/create",
    redirect: {"name":"properties-property-create-address"},
    children: [
  {
    name: addresseb5oMlsn9pMeta?.name ?? "properties-property-create-address",
    path: addresseb5oMlsn9pMeta?.path ?? "address",
    meta: addresseb5oMlsn9pMeta || {},
    alias: addresseb5oMlsn9pMeta?.alias || [],
    redirect: addresseb5oMlsn9pMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/properties/pages/property/address.vue").then(m => m.default || m)
  },
  {
    name: aboutxr9lJP0mJ2Meta?.name ?? "properties-property-create-about",
    path: aboutxr9lJP0mJ2Meta?.path ?? "about",
    meta: aboutxr9lJP0mJ2Meta || {},
    alias: aboutxr9lJP0mJ2Meta?.alias || [],
    redirect: aboutxr9lJP0mJ2Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/properties/pages/property/about.vue").then(m => m.default || m)
  },
  {
    name: listinglIgQp6jEKbMeta?.name ?? "properties-property-create-listing",
    path: listinglIgQp6jEKbMeta?.path ?? "listing",
    meta: listinglIgQp6jEKbMeta || {},
    alias: listinglIgQp6jEKbMeta?.alias || [],
    redirect: listinglIgQp6jEKbMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/properties/pages/property/listing.vue").then(m => m.default || m)
  },
  {
    name: mediaV0POlaEfHcMeta?.name ?? "properties-property-create-media",
    path: mediaV0POlaEfHcMeta?.path ?? "media",
    meta: mediaV0POlaEfHcMeta || {},
    alias: mediaV0POlaEfHcMeta?.alias || [],
    redirect: mediaV0POlaEfHcMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/properties/pages/property/media.vue").then(m => m.default || m)
  }
],
    meta: propertyNzo81BDKpgMeta || {},
    alias: propertyNzo81BDKpgMeta?.alias || [],
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/properties/pages/property.vue").then(m => m.default || m)
  },
  {
    name: editEueO4uE4TbMeta?.name ?? "properties-property-edit",
    path: editEueO4uE4TbMeta?.path ?? "/property/:uuid/edit",
    redirect: {"name":"properties-property-edit-address"},
    children: [
  {
    name: addresseb5oMlsn9pMeta?.name ?? "properties-property-edit-address",
    path: addresseb5oMlsn9pMeta?.path ?? "address",
    meta: addresseb5oMlsn9pMeta || {},
    alias: addresseb5oMlsn9pMeta?.alias || [],
    redirect: addresseb5oMlsn9pMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/properties/pages/property/address.vue").then(m => m.default || m)
  },
  {
    name: aboutxr9lJP0mJ2Meta?.name ?? "properties-property-edit-about",
    path: aboutxr9lJP0mJ2Meta?.path ?? "about",
    meta: aboutxr9lJP0mJ2Meta || {},
    alias: aboutxr9lJP0mJ2Meta?.alias || [],
    redirect: aboutxr9lJP0mJ2Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/properties/pages/property/about.vue").then(m => m.default || m)
  },
  {
    name: listinglIgQp6jEKbMeta?.name ?? "properties-property-edit-listing",
    path: listinglIgQp6jEKbMeta?.path ?? "listing",
    meta: listinglIgQp6jEKbMeta || {},
    alias: listinglIgQp6jEKbMeta?.alias || [],
    redirect: listinglIgQp6jEKbMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/properties/pages/property/listing.vue").then(m => m.default || m)
  },
  {
    name: mediaV0POlaEfHcMeta?.name ?? "properties-property-edit-media",
    path: mediaV0POlaEfHcMeta?.path ?? "media",
    meta: mediaV0POlaEfHcMeta || {},
    alias: mediaV0POlaEfHcMeta?.alias || [],
    redirect: mediaV0POlaEfHcMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/properties/pages/property/media.vue").then(m => m.default || m)
  }
],
    meta: editEueO4uE4TbMeta || {},
    alias: editEueO4uE4TbMeta?.alias || [],
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/properties/pages/property/[uuid]/edit.vue").then(m => m.default || m)
  },
  {
    name: partnership5TIyQmOn2JMeta?.name ?? "resources-partnership",
    path: partnership5TIyQmOn2JMeta?.path ?? "/resources/partnership",
    meta: partnership5TIyQmOn2JMeta || {},
    alias: partnership5TIyQmOn2JMeta?.alias || [],
    redirect: partnership5TIyQmOn2JMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/resources/pages/partnership.vue").then(m => m.default || m)
  },
  {
    name: marketing_45materialZRG9LB8x8RMeta?.name ?? "resources-marketing-material",
    path: marketing_45materialZRG9LB8x8RMeta?.path ?? "/resources/marketing-material",
    meta: marketing_45materialZRG9LB8x8RMeta || {},
    alias: marketing_45materialZRG9LB8x8RMeta?.alias || [],
    redirect: marketing_45materialZRG9LB8x8RMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/resources/pages/marketing-material.vue").then(m => m.default || m)
  },
  {
    name: indexgzFWJVbQD4Meta?.name ?? "tenancies",
    path: indexgzFWJVbQD4Meta?.path ?? "/tenancies",
    meta: indexgzFWJVbQD4Meta || {},
    alias: indexgzFWJVbQD4Meta?.alias || [],
    redirect: indexgzFWJVbQD4Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/tenancies/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexRfAs06cMh8Meta?.name ?? "utilities",
    path: indexRfAs06cMh8Meta?.path ?? "/utilities",
    meta: indexRfAs06cMh8Meta || {},
    alias: indexRfAs06cMh8Meta?.alias || [],
    redirect: indexRfAs06cMh8Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/utilities/pages/index.vue").then(m => m.default || m)
  },
  {
    name: orderbIXSqmodEcMeta?.name ?? "utilities-order",
    path: orderbIXSqmodEcMeta?.path ?? "/utilities/order",
    redirect: {"name":"utilities-order-details"},
    children: [
  {
    name: detailsD0cwLLlkTaMeta?.name ?? "utilities-order-details",
    path: detailsD0cwLLlkTaMeta?.path ?? "details",
    meta: detailsD0cwLLlkTaMeta || {},
    alias: detailsD0cwLLlkTaMeta?.alias || [],
    redirect: detailsD0cwLLlkTaMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/utilities/pages/order/details.vue").then(m => m.default || m)
  },
  {
    name: tenantsVQ5YXxmIbWMeta?.name ?? "utilities-order-tenants",
    path: tenantsVQ5YXxmIbWMeta?.path ?? "tenants",
    meta: tenantsVQ5YXxmIbWMeta || {},
    alias: tenantsVQ5YXxmIbWMeta?.alias || [],
    redirect: tenantsVQ5YXxmIbWMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/utilities/pages/order/tenants.vue").then(m => m.default || m)
  },
  {
    name: summaryfDENC23bmVMeta?.name ?? "utilities-order-summary",
    path: summaryfDENC23bmVMeta?.path ?? "summary",
    meta: summaryfDENC23bmVMeta || {},
    alias: summaryfDENC23bmVMeta?.alias || [],
    redirect: summaryfDENC23bmVMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/utilities/pages/order/summary.vue").then(m => m.default || m)
  },
  {
    name: previewfTR7CudahaMeta?.name ?? "utilities-order-preview",
    path: previewfTR7CudahaMeta?.path ?? "preview",
    meta: previewfTR7CudahaMeta || {},
    alias: previewfTR7CudahaMeta?.alias || [],
    redirect: previewfTR7CudahaMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/utilities/pages/order/preview.vue").then(m => m.default || m)
  }
],
    meta: orderbIXSqmodEcMeta || {},
    alias: orderbIXSqmodEcMeta?.alias || [],
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/utilities/pages/order.vue").then(m => m.default || m)
  },
  {
    name: indexK6schfY8rIMeta?.name ?? "utilities-order-edit",
    path: indexK6schfY8rIMeta?.path ?? "/utilities/order/:uuid",
    redirect: {"name":"utilities-order-edit-details"},
    children: [
  {
    name: detailsD0cwLLlkTaMeta?.name ?? "utilities-order-edit-details",
    path: detailsD0cwLLlkTaMeta?.path ?? "details",
    meta: detailsD0cwLLlkTaMeta || {},
    alias: detailsD0cwLLlkTaMeta?.alias || [],
    redirect: detailsD0cwLLlkTaMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/utilities/pages/order/details.vue").then(m => m.default || m)
  },
  {
    name: tenantsVQ5YXxmIbWMeta?.name ?? "utilities-order-edit-tenants",
    path: tenantsVQ5YXxmIbWMeta?.path ?? "tenants",
    meta: tenantsVQ5YXxmIbWMeta || {},
    alias: tenantsVQ5YXxmIbWMeta?.alias || [],
    redirect: tenantsVQ5YXxmIbWMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/utilities/pages/order/tenants.vue").then(m => m.default || m)
  },
  {
    name: summaryfDENC23bmVMeta?.name ?? "utilities-order-edit-summary",
    path: summaryfDENC23bmVMeta?.path ?? "summary",
    meta: summaryfDENC23bmVMeta || {},
    alias: summaryfDENC23bmVMeta?.alias || [],
    redirect: summaryfDENC23bmVMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/utilities/pages/order/summary.vue").then(m => m.default || m)
  },
  {
    name: previewfTR7CudahaMeta?.name ?? "utilities-order-edit-preview",
    path: previewfTR7CudahaMeta?.path ?? "preview",
    meta: previewfTR7CudahaMeta || {},
    alias: previewfTR7CudahaMeta?.alias || [],
    redirect: previewfTR7CudahaMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/utilities/pages/order/preview.vue").then(m => m.default || m)
  },
  {
    name: confirmationfMW8RsMlMHMeta?.name ?? "utilities-order-edit-confirmation",
    path: confirmationfMW8RsMlMHMeta?.path ?? "confirmation",
    meta: confirmationfMW8RsMlMHMeta || {},
    alias: confirmationfMW8RsMlMHMeta?.alias || [],
    redirect: confirmationfMW8RsMlMHMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/utilities/pages/order/confirmation.vue").then(m => m.default || m)
  }
],
    meta: indexK6schfY8rIMeta || {},
    alias: indexK6schfY8rIMeta?.alias || [],
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_prod/agents/modules/utilities/pages/order/[uuid]/index.vue").then(m => m.default || m)
  }
]